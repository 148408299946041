import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, List, Popup } from "semantic-ui-react";
import Card from "../../../../components/Card/Card";
import { ModalAddParticipant } from "../Modals/ModalAddParticipant/ModalAddParticipant";
import { DateTimeFormatOptions } from "../../../../models/DateTimeFormatOptions";
import { getLanguageNavigator } from "../../../../util/languages";
import { EnumLang } from "../../../../enum/EnumLang";
import { eventsGetInfo, getUserDetails,  deleteMeetingAtendeeFunction, getMeetingDocumentation, getMeetingDocument } from "../../../../assets/api";
import { AuthContext } from "../../../../context/AuthContext";
import {
  defaultEventObject,
  EventObject,
} from "../../../../models/EventObject";
import CardInformation from "./components/CardInformation";
import CodeCell from "../../../../components/ServicesDetails/BOServiceMeeting/BOTableMeeting/components/BOTableMeeting/components/CodeCell";
import Attendee from "./components/Attendee";
import { DocumentApiResponse, defaultDocumentApiResponse } from "../../../../models/DocumentationObject";
import { saveAs } from 'file-saver';

const options: DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

interface CardMeetingProps {
  queueMeeting: any;
  start: string;
  meetingDate: Date;
}

const CardMeeting: React.FC<CardMeetingProps> = (props: CardMeetingProps) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [modalParticipant, setModalParticipant] = useState<boolean>(false);
  const [lang, setLang] = useState<EnumLang>(EnumLang.ca);
  const [eventInfo, setEventInfo] = useState<EventObject>(defaultEventObject);
  const [documentationInfo, setDocumentationInfo] = useState<DocumentApiResponse>(defaultDocumentApiResponse);
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    const aux = getLanguageNavigator();
    setLang(aux);
  }, []);

  useEffect(() => {
    (async () => {
      if (props.queueMeeting.MeetingID !== "") {
        const { backoffice } = await authContext.getTokenForScopes();
        const user = await getUserDetails(
          props.queueMeeting.MeetingID, 
          backoffice
        ).then((response) => response.json());
        
        const { shared } = await authContext.getTokenForScopes();
        const eventInfo: EventObject = await eventsGetInfo(
          props.queueMeeting.MeetingID,
          shared
        );
        const documentationInfo: DocumentApiResponse = await getMeetingDocumentation(
          props.queueMeeting.MeetingID,
          shared
        );
        setDocumentationInfo(documentationInfo);
        console.log("EV INFO", eventInfo);
        setEventInfo(eventInfo);
        console.log("Event Info", eventInfo.subjects1);
        setUser(user);
      }
    })();
  }, [props.queueMeeting.MeetingID, modalParticipant]);
  
  const attendee = user.attendees ? user.attendees[0] : {};
  const { fullName, email, phone, personId } = attendee;
  const paramsCard = {
    name_surnames: fullName,
    nif: personId,
    telephone: phone,
    email: email,
    isOtpAvailable: eventInfo.isOtpAvailable,
    observations: eventInfo.observations,
    comment: eventInfo.comment,
    subjects1: eventInfo.subjects1,
    subjects2: eventInfo.subjects2,
    subjects3: eventInfo.subjects3
  };
  const download = async (info: any) => {
    const { shared } = await authContext.getTokenForScopes();
    const documentationInfo: Response = await getMeetingDocument(
      info,
      shared
    );
    const blob = await documentationInfo.blob();
    saveAs(blob, info.split('/')[info.split('/').length - 1]);
  }
  const  handleDeleteClick = async (attendee: any) => {
    await deleteMeetingAtendeeFunction(props.queueMeeting.MeetingID, attendee.fullName, attendee.email, attendee.phone, authContext.account.access_token);
    const { backoffice } = await authContext.getTokenForScopes();
    const user1 = await getUserDetails(
      props.queueMeeting.MeetingID, 
      backoffice
    ).then((response) => response.json());
    setUser(user1);
  }

  return (
    <>
      <Card>
        <Card.Body>
          {props.start?.length !== undefined && props.start.length > 0 ? (
            <h2>
              {t("home.card.start_time")}
              {props.start},{" "}
              {props.meetingDate
                .toLocaleDateString(lang, options)
                .charAt(0)
                .toUpperCase()}
              {props.meetingDate.toLocaleDateString(lang, options).substring(1)}
            </h2>
          ) : (
            <h2>{t("home.card.no_event_assigned")}</h2>
          )}
          <hr />
          {props.start?.length !== undefined && props.start.length > 0 ? (
            <ul>
              {Object.entries(paramsCard).map(([key, value]) => (
                <CardInformation type={key} value={value} />
              ))}
              <li>
                <div
                  className={`d-flex flex-${
                    !user.attendees || user.attendees.length <= 1
                      ? "row"
                      : "column"
                  } margin-top-card-small`}
                >
                  <h3>{t("home.card.participants")}</h3>
                  <ul>
                    {user?.attendees
                      ?.slice(1)
                      .map((attendee: any, index: number) => {
                        return (
                          <div style={{ display : "flex"}}>
                            <Attendee attendee={attendee} index={index}  />
                            <button style={{backgroundColor: 'red', marginLeft: "10px", border: 0, borderRadius: "12px", height: "20px", display: "flex", alignItems: "center", textAlign: "center" }}
                            onClick={() => handleDeleteClick(attendee) }>
                              {t("home.card.delete_participant")}
                            </button>
                          </div>
                        )
                      })}
                      
                  </ul>
                  {(!user.attendees || user.attendees.length <= 1) && (
                    <h3 style={{ marginLeft: -30 }}>
                      {t("home.card.participants_empty")}
                    </h3>
                  )}
                </div>
              </li>
              
              <li>
                <div
                  className={`d-flex flex-column margin-top-card-small`}
                >
                  <h3>{t("home.card.documentation")}</h3>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {documentationInfo.result
                          ?.slice(0)
                          .map((res) => {
                            console.log(res);
                            return (
                              <li key={res.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <span style={{ flex: '1', overflow: 'hidden', textOverflow: 'ellipsis' }}>· {res.name}</span>
                                <Button onClick={() => download(res.link)} floated="right" inverted positive icon={"download"}></Button>
                                </li>
                            );
                          })}
                      </ul>
                      {(!documentationInfo.result || documentationInfo.result.length == 0) && (
                        <h3 style={{ marginLeft: 5 }}>
                          <span>{t("home.card.participants_empty")}</span>
                        </h3>
                      )}
                </div>
              </li>
            </ul>
          ) : (
            <>
              <h3>{t("home.card.retrieve_event_using_button")}</h3>
            </>
          )}
          {props.queueMeeting.MeetingURL.length > 0 && (
            <>
              <hr />
              <Button positive>
                <a target="_blank" href={props.queueMeeting.MeetingURL}>
                  <span className="text-white">
                    <Icon name="video" />
                    {t("btn.start_videocall")}
                  </span>
                </a>
              </Button>
              {eventInfo.isOtpAvailable !== false && (
                <Button
                  negative
                  className="float-right"
                  icon="plus"
                  content="Añadir Ciudadano"
                  onClick={() => setModalParticipant(true)}
                >
                  {t("btn.add_user_videocall")}
                </Button>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      <ModalAddParticipant
        eventId={props.queueMeeting.MeetingID}
        setModal={setModalParticipant}
        modal={modalParticipant}
      />
    </>
  );
};
export default CardMeeting;
